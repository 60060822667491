import { defineComponent, ref, onMounted, computed, watch } from 'vue';
import { IStemdoer } from '@/shared/model/stemdoer.model';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';
import { Certification, ICertification } from '@/shared/model/certification.model';
import { IRate } from '@/shared/model/rate.model';
import Sbutton from '@/components/stemdo-components/s-button/SButton.vue';
import { useI18N } from '@/plugins/i18n';
import { StemdoerStatus } from '@/shared/model/enumerations/stemdoer-status.model';
import StemdoerDetail from '@/sections/shared/stemdoer-detail/StemdoerDetail.vue';

export default defineComponent({
  props: {
    stemdoer: {
      type: Object as () => IStemdoer,
      required: true,
    },
    addStemdoerToCart: {
      type: Function,
      required: true,
    },
    rates: {
      type: Array as () => IRate[],
      required: true,
    },
    openStemdoerDetailModal: {
      type: Function,
      required: true,
    },
  },
  components: {
    SAvatarStemdoer,
    StemdoerDetail,
    Sbutton,
  },
  setup(props) {
    const i18n = useI18N();
    const mutableStemdoer = ref(props.stemdoer);
    const isLoaded = ref(false);
    const uniqueCertifications = ref<{ [key: string]: string }>({});

    onMounted(() => {
      badgesFormatter();
      certificationFormatter();
    });

    const resetOverflow = () => {
      document.documentElement.style.overflow = 'auto';
    };

    const addToCart = (stemdoer: IStemdoer) => {
      props.addStemdoerToCart(stemdoer);
    };

    const certificationFormatter = () => {
      isLoaded.value = false;
      mutableStemdoer.value['certifications'].forEach((certification: ICertification) => {
        if (certification['validation'] == true) {
          const url = Certification.getCertificationImgUrlByName(certification['name']);
          certification['imgUrl'] = url;
          uniqueCertifications[certification['name']] = certification['imgUrl'];
        }
      });
      isLoaded.value = true;
    };

    const formatBadges = technologyScores => {
      const badgesSkills = technologyScores.slice(0, 10).map(tech => ({
        id: tech.idTechnology,
        technology: i18n.t(`stemdoPlatformApp.technology.list.${tech.idTechnology}`),
        skill: tech.score,
      }));

      badgesSkills.sort((a, b) => b.skill - a.skill || a.id - b.id);

      return badgesSkills;
    };

    const badgesFormatter = () => {
      mutableStemdoer.value['badgesSkills'] = formatBadges(mutableStemdoer.value['technologyScores']);
      isLoaded.value = true;
    };

    const getCertification = () => {
      mutableStemdoer.value['certifications'].forEach((certification: ICertification) => {});
      return mutableStemdoer.value['certifications'].filter(certification => {
        return certification['imgUrl'] != '';
      });
    };

    return {
      getCertification,
      mutableStemdoer,
      isLoaded,
      addToCart,
      Certification,
      StemdoerStatus,
      resetOverflow,
    };
  },
});
